<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <b-col
          sm="12"
          class="d-flex align-items-center mb-2"
        >
          <h4 class="mb-0 font-weight-normal">
            Tasks
          </h4>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchTasks"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="totalItems && perPage !== 'all'"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BPagination,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useTaskList from './useTaskList'
import storeModule from '@/views/admin/staff/staffStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    applicationId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-staff-task'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      resolveStatusVariant,

      refetchData,
    } = useTaskList(props.applicationId)

    return {
      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      resolveStatusVariant,

      refetchData,
    }
  },
}
</script>
